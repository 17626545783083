import React, { ReactNode } from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { useTwitterBtnStyles } from '../hooks/useTwitterBtnStyles';

export type ButtonProps = {
    id?: string,
    children: ReactNode,
    disabled?: boolean | undefined,
    onClick?: any | undefined,
    variant: "outlined" | "contained" | "text" | undefined,
    color: "inherit" | "default" | "primary" | "secondary" | undefined,
    type: "button" | "reset" | "submit" | undefined,
    fontSize: string,
    heightOverride?: string,
    widthOverride?: string,
    minWidthOverride?: string,
    colorOverride?: string,
}

export const Button = ({ id, disabled, variant, color, type, children, onClick, fontSize, heightOverride, widthOverride, minWidthOverride, colorOverride}: ButtonProps) => {
    const buttonStyles = useTwitterBtnStyles();

    const renderButton = () => {
        return (
            <MuiButton
                id={id}
                classes={buttonStyles}
                disabled={disabled}
                variant={variant}
                color={color}
                disableElevation
                type={type}
                onClick={onClick}
                style={{ fontSize: fontSize, height: heightOverride, width: widthOverride, minWidth: minWidthOverride, backgroundColor: colorOverride }}
            >
                {children}
            </MuiButton>
        );
    }

    return renderButton();
};

export default Button;
