import React, { Fragment,  useContext, useState } from 'react';
import { Divider, Grid, Paper as CustomPaper, Typography,Tooltip, makeStyles, createStyles } from '@material-ui/core';
import { usePaperStyles } from '../hooks/usePaperStyles';
import { AuthorizeInputType, SearchResult } from '../types/api';
import {
    getMDYFormat,
    getFullName,
    getLastSSN,
    sanitizeInput,
    getFormattedDate,
    getFormattedDateInDaysAgo,
    getRegion
} from '../utils/AccountDetailsUtil';
import Button from './Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {authorizeByPECUser} from '../features/search/api'
import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ClientConfigContext } from "../config/clientConfig";
import { SessionContext } from '../session/sessionContext';
import {CardType} from "../types/cardTypes";
import { Theme } from '../theme';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

type mrn = {
    id: string, system: string
}

export type PaperProps = {
    variant: "outlined" | undefined,
    inputData: SearchResult,
    mrns: mrn[],
    cardType: CardType,
    onUnlink: any,
    verifiedWith: string
}
interface styleProps {
    region?: string
}

/* Gets the Card helmet back ground color based on the region text passed */
const getEHRHelmetColor = (theme: Theme,region?: string) => {
    return theme.palette.regionColor?.find(rc => rc.name === region?.toLowerCase())?.helmetColor;
}

/* Gets the Card MRN back ground color based on the region text passed */
const getMRNColor = (theme: Theme, region?: string) => {
    return theme.palette.regionColor?.find(rc => rc.name === region?.toLowerCase())?.mrnColor;
}

/* Defines card heading styles for helmet and mrn based on the region */
const cardHeadingStyles = makeStyles((theme: Theme) =>
    createStyles({
        helmetColor: (props: styleProps) => ({
            backgroundColor: getEHRHelmetColor(theme, props.region),
            borderColor: getEHRHelmetColor(theme, props.region)
        }),
        mrnColor: (props: styleProps) => ({
            backgroundColor: getMRNColor(theme, props.region),
            borderColor: getMRNColor(theme, props.region)
        })
    }),
)


export const Paper = ({ variant, inputData, mrns, cardType , onUnlink, verifiedWith }: PaperProps) => {

    const paperStyles = usePaperStyles();
    const [data, setData] = useState<SearchResult>(inputData)
    const [verificationWith,setVerificationWith] = useState(verifiedWith)
    const [errorMessage, setErrorMessage] = useState("");

    const sessionId = useContext(SessionContext);
    const clientConfiguration = useContext(ClientConfigContext);
    const msalContext = useMsal();
    const instance  = msalContext.instance;
    const accounts = msalContext.accounts as AccountInfo[];

    const getCardHeading = (mrn:string, classes: ClassNameMap<"helmetColor" | "mrnColor">, region?: string) => {
        return(<Fragment key={mrn}>
            <Grid item xs={1}>
                <Typography variant="subtitle1" className={`accountDetailsHelmet ${classes.helmetColor}`}>
                    {region}
                </Typography>
            </Grid>
            <Grid item className='mr10'>
                <Typography variant="subtitle1" className={`accountDetailsMRN ${classes.mrnColor}`}>
                    <span >{mrn}</span>
                </Typography>
            </Grid>
        </Fragment>);
    };
    const getCardHelmet = () => {
        if (cardType === CardType.NormalCard) {
            return (
                <>
                    <Grid container alignItems='flex-start' direction='column' className='cardHeader'>
                        <Grid item>
                            <Typography variant="subtitle2" className="accountDetailsHeader">
                                MRN
                            </Typography>
                        </Grid>
                        {
                             data.system ?
                                <Grid container alignItems='flex-start' direction='row' className='mt5'>
                                    {
                                        mrns?.length > 0 ? mrns?.map(mrnData => {
                                            const regionText = getRegion(mrnData.system, clientConfiguration);
                                            return (getCardHeading(mrnData.id, cardHeadingStyles({ region: regionText }), regionText))
                                        }) : () => {
                                            const regionText = getRegion(data.system, clientConfiguration);
                                            getCardHeading("NON-Patient Proxy", cardHeadingStyles({ region: regionText }), regionText)
                                        }
                                    }
                                </Grid>:
                                <Grid item>
                                    <Typography variant="subtitle1" className='mt10'>
                                        --
                                    </Typography>
                                </Grid>
                        }

                    </Grid>
                </>
            )
        } else {
            const region = getRegion(data.system, clientConfiguration);
            const cardHeadingClasses = cardHeadingStyles({region: region});
            return (
                <>
                    <Grid container alignItems='center' direction='row' className={`linkedRecordHelmetRegion ${cardHeadingClasses.helmetColor}`}>
                        <Grid item xs>
                            <Typography variant="h5" className='mrnRegion'>
                                {region}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="subtitle1" className='ml40'>
                            {data?.mrn ? <><span className="accountDetailsHeader">MRN:</span><span className="accountDetailsValue">{data?.mrn}</span></>
                            : <span className="accountDetailsValue">NON-PATIENT PROXY</span>}
                            </Typography>

                        </Grid>
                    </Grid>
                </>
            )
        }
    }

    const getAccountAuthItem = () => {
        if(!data?.requireMigrateAccount){
            return(
                    <Grid item className='accountAuthItem'>
                        {!isAuthDetailsPresent() &&
                        <Grid item>
                            <Typography className='accountAuthMessage'>
                                This account is linked, however, authorization process is not complete. Please authorize this account now.
                            </Typography>
                        </Grid>}
                        <Grid container item xs alignItems='flex-start' >
                            <Grid item xs={6}>
                                {!isAuthDetailsPresent() ?
                                <><Button type="button" variant="contained" color="secondary" fontSize="12px" minWidthOverride='185px !important' onClick={handleAuthorizeClick}>
                                    AUTHORIZE ACCOUNT
                                </Button>
                                {renderErrorMessage()}</>:
                                <Typography variant="subtitle2" className="accountDetailsHeader">
                                    Link updated: {sanitizeInput(getMDYFormat(data?.authorizationDate, false))}
                                </Typography>}
                            </Grid>
                            <Grid item xs={6} className='unLink'>
                                <Button type="button" variant="outlined" color="secondary" fontSize="12px" onClick={openLinkRemovalModal}>
                                    UNLINK
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
            )
        }
    }

    // TODO: WHY NOT sanitizeInput the input fields once????????
    const getDisplayItemAccordion = (label:string, input: string | undefined) => {
        const values = input?.split('|');
        if (values && values.length > 1) {
            return (
                <>
                    <Accordion square>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography noWrap variant="subtitle2" className="accountDetailsHeader">
                                {`${label}`}<span className='boldText'> ({values.length})</span>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction='column' className='mt10'>
                                {
                                    values.map(myChartName => {
                                        return(
                                            <Fragment key={ myChartName }>
                                                <Grid item>
                                                    {label == "MyChart username" ?
                                                        <Tooltip title={myChartName} placement="top-start">
                                                            <Typography noWrap variant="subtitle2" className="accountDetailsHeader mt3">
                                                                <span className='boldText'> {myChartName.split(' ')[0]} </span> {myChartName.split(' ')[1]}
                                                            </Typography>
                                                        </Tooltip>
                                                        :
                                                        <Typography noWrap variant="subtitle1" className="accountDetailsValue mt3">
                                                            {myChartName}
                                                        </Typography>
                                                    }
                                                </Grid>
                                            </Fragment>
                                        )
                                    })
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </>)
        } else {
            return (
                <>
                    <Typography variant="subtitle2" className="accountDetailsHeader">
                        {label}
                    </Typography>
                    { label == "MyChart username" ?
                        <Tooltip title={sanitizeInput(input)} placement="top-start">
                            <Typography noWrap variant="subtitle1" className="accountDetailsValue">
                                {sanitizeInput(input)}
                            </Typography>
                        </Tooltip>:
                        <Typography noWrap variant="subtitle1" className="accountDetailsValue">
                            {sanitizeInput(input)}
                        </Typography>
                    }
                </>
            )
        }
    }

    // TODO: Generic Paper component knows about a open link removal model
    const openLinkRemovalModal = () => {
        const fullName = sanitizeInput(getFullName(data?.ehrDemographics?.firstName, data?.ehrDemographics?.lastName));
        const regionLabel = getRegion(data.system, clientConfiguration);
        onUnlink(
            fullName,
            data.mychartUserName,
            data.mrn,
            regionLabel
        );
    };

    const handleAuthorizeClick = async () => {
        const apiData: AuthorizeInputType = {
            epi: data?.epi ?? "",
            healthSystemId: data.healthSystemId,
            authorizationDetails: {
                mrn: data.mrn,
                myChartUsername: data.mychartUserName,
                pecID: accounts?.[0].username
            }
          }
          const domain = data.system;
          const result  = await authorizeByPECUser(apiData, domain, instance,accounts,clientConfiguration,sessionId);
          if (result) {
              const tempData:SearchResult  = {...data} ;
              tempData.authorizationDate = getFormattedDate(new Date().toString());
              setVerificationWith("PEC Linked Record Entry");
              tempData.daysSinceAuth = getFormattedDateInDaysAgo(tempData.authorizationDate)
              tempData.isAuthorized = true;
              setData(tempData);
          }
          else{
              setErrorMessage("Account authorization has failed")
          }
       }
    const isAuthDetailsPresent = ()=>{
        return data?.isAuthorized;
    }
    const renderErrorMessage = () => {
        return (
            errorMessage &&
            <Typography variant="subtitle1" color='error' align='right' >
                {errorMessage}
            </Typography>
        )
    }
    const renderPaper = () => {
        return (
            <CustomPaper
                classes={{...paperStyles}}
                variant={variant}
            >
                {getCardHelmet()}
                <Divider variant='middle' />
                {
                    cardType === CardType.NormalCard ?

                        <Grid container alignItems='flex-start' direction='row' className='normalCard'>
                            <Grid container item xs alignItems='flex-start' direction='column'>
                                <Grid item className='mb9'>
                                    {getDisplayItemAccordion("Name", getFullName(data?.ehrDemographics?.firstName, data?.ehrDemographics?.lastName))}
                                </Grid>
                                <Grid item className='mb9'>
                                    {getDisplayItemAccordion("Last login",data?.userLastSignedInDate)}
                                </Grid>
                                <Grid item className='myChartName'>
                                    {getDisplayItemAccordion("MyChart username",data?.mychartUserName)}
                                </Grid>
                                <Grid item className='mb9'>
                                    {getDisplayItemAccordion("Email", data?.ehrDemographics?.email)}
                                </Grid>
                            </Grid>
                            <Grid container item xs alignItems='flex-start' direction='column'>
                                <Grid item className='mb9'>
                                    {getDisplayItemAccordion("Sign-in name",data?.userProvidenceAccountUsername)}
                                </Grid>
                                <Grid item className='mb9'>
                                    {getDisplayItemAccordion("Last 4 SSN",getLastSSN(data?.ehrDemographics?.ssn))}
                                </Grid>
                                <Grid item className='mb9'>
                                    {getDisplayItemAccordion("DOB",getMDYFormat(data?.ehrDemographics?.dateOfBirth))}
                                </Grid>
                                {data?.accountAuthorizations?.length == 1 &&  <Grid item className='mb9'>
                                    {getDisplayItemAccordion("Status", verificationWith)}
                                </Grid>}
                            </Grid>
                        </Grid>
                        :
                        <Grid container alignItems='flex-start' direction='row' className='linkedCard'>
                            <Grid container item xs alignItems='flex-start' direction='column'>
                                <Grid item className='mb9'>
                                    <Typography variant="subtitle2" className="accountDetailsValue">
                                        {getFullName(data?.ehrDemographics?.firstName, data?.ehrDemographics?.lastName)}
                                    </Typography>
                                    <Typography noWrap variant="subtitle1" className="accountDetailsHeader">
                                        {sanitizeInput(data?.mychartUserName)}
                                    </Typography>
                                </Grid>
                                {isAuthDetailsPresent() &&<Grid item className='mb9'>
                                    <Typography variant="subtitle2" className="accountDetailsValue">
                                        Verified on
                                    </Typography>
                                    <Typography variant="subtitle1" className="accountDetailsHeader">
                                        {sanitizeInput(data?.authorizationDate)}
                                    </Typography>
                                    {data?.authorizationDate  &&<Typography variant="subtitle1" className="accountDetailsHeader mt-7">
                                        ({sanitizeInput(data?.daysSinceAuth)})
                                    </Typography> }
                                </Grid>}

                            </Grid>
                            <Grid container item xs alignItems='flex-start' direction='column'>

                                <Grid item className='ssnComponent '>
                                    <Typography variant="subtitle2">
                                        <span className='accountDetailsHeader'>Last 4 SSN:</span>
                                        <span className='accountDetailsValue'>{data?.mrn ? sanitizeInput(getLastSSN(data?.ehrDemographics?.ssn)) : "n/a"}</span>
                                    </Typography>
                                    <Typography variant="subtitle2" className="accountDetailsHeader">
                                        <span className='accountDetailsHeader'>DOB:</span>
                                        <span className='accountDetailsValue'>{data?.mrn ? (sanitizeInput(getMDYFormat(data?.ehrDemographics?.dateOfBirth))) : "n/a"}</span>
                                    </Typography>
                                </Grid>
                                {isAuthDetailsPresent() && <Grid item className='mb30'>
                                    <Typography variant="subtitle2" className="accountDetailsValue">
                                        Verified with
                                    </Typography>
                                    <Typography variant="subtitle1" className="accountDetailsHeader">
                                        {sanitizeInput(verificationWith)}
                                    </Typography>
                                </Grid>}
                            </Grid>
                            {getAccountAuthItem()}
                        </Grid>
                }
            </CustomPaper>
        );
    };

    return renderPaper();
};

export default Paper;


