import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
  
export interface Theme extends ThemeOptions {
    palette: ThemeOptions['palette'] & { primary: { border: string } } 
        & {regionColor: [{name: string, helmetColor: string, mrnColor: string}]}
}

export const getTheme = (themeConfig: Theme): Theme => {
    return {
        palette: {
            text: themeConfig.palette.text,
            primary: themeConfig.palette.primary,
            secondary: themeConfig.palette.secondary,
            regionColor: themeConfig.palette.regionColor
        },
        overrides: {
            MuiTypography: themeConfig.overrides?.MuiTypography,
            MuiInputBase: themeConfig.overrides?.MuiInputBase
        }
    }
}
