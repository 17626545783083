import React, { useContext, useEffect, useState } from 'react';
import { Typography, Grid, TextField, Hidden } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useMsal } from '@azure/msal-react';
import Button from '../../elements/Button';
import '../../style.css';
import { ClientConfigContext } from "../../config/clientConfig";
import { AccountInfo } from '@azure/msal-common';
import { AccountAuthorization, PatientAdminServiceIdentity, SearchResult } from '../../types/api';
import { auditPageView, searchAccounts } from './api';
import MultipleAccountsDisplayComponent from './components/MultipleAccountsDisplayComponent';
import SearchResultDataComponent from './components/SearchResultDataComponent';
import dayjs from 'dayjs'
// TODO: Search for " days ago" and use the getFormattedDateInDaysAgo
import { DATE_TIME_FORMAT, DISPLAY_PLACEHOLDER, getDaysAgo, getDisplayUserName } from '../../utils/AccountDetailsUtil';
import { SessionContext } from '../../session/sessionContext';
import { MfaState } from "../../types/mfaState";
import withLogger from './components/HOC/WithLogger';

function SearchPage() {
    const msalContext = useMsal();
    const { instance } = msalContext;
    const accounts = msalContext.accounts as AccountInfo[];
    const clientConfiguration = useContext(ClientConfigContext);
    const sessionId = useContext(SessionContext);
    const { handleSubmit, errors, control, formState, setValue } = useForm({ mode: 'onChange' });
    const [loading, setLoading] = useState(false);
    const { isDirty, isValid } = formState;
    const [searchTerm, setSearchTerm] = useState("");
    const [multipleData, setMultipleData] = useState<SearchResult[]>();

    // TODO: The variable naming is extremely confusing. We cannot use similar naming for different controls
    const [searchDataDisplay, setSearchDataDisplay] = useState(false);
    const [searchDisplayData, setSearchDisplayData] = useState<SearchResult[]>();

    const logout = () => {
        // The account's ID Token must contain the login_hint optional claim to avoid the account picker
        instance.logout({ account: instance.getAllAccounts()[0]});
    };

    /**
     * Search for an account. In effect, this refreshes most of the page.
     * @param searchTerm email, mrn, or epi
     * @param region a fully qualified ehr system name
     */
    const onSearch = async (searchTerm: string): Promise<void> => {
        // setEmailChangeAttempted(false);
        // setPasswordChangeAttempted(false);
        setSearchTerm(searchTerm);
        searchMultiAccounts(searchTerm);
    };

    const searchMultiAccounts = async (accountId: string, region?: string, healthSystemId?: string) => {
        try {
            if (region && healthSystemId && searchTerm !== accountId) {
                //After the update Email flow, if  link record is tried,
                //update the search Textbox value with the new email
                setValue("accountId", "Updating search...");
            }
            setSearchDataDisplay(region ? true : false);
            setMultipleData(undefined);
            setLoading(true);
            const data = await searchAccounts(accountId, "", instance, accounts, clientConfiguration, sessionId);
            const patientDtos: PatientAdminServiceIdentity[] = data.patientAdminServiceIdentityDtos;
            const searchResults = mapSearchResults(patientDtos, data.inputSearchType);
            if (searchResults) {
                if (region && healthSystemId) {
                    const selectedItem = searchResults.filter(x => x.healthSystemId === healthSystemId)
                    setSearchDisplayData(selectedItem?.length > 0 ? selectedItem : searchResults);
                    setSearchTerm(accountId);
                    setValue("accountId", accountId);
                }
                setMultipleData(searchResults);
                setLoading(false);
            }
        } catch (err: any) {
            setLoading(false);
        }
    }

    const getAuthorizationDate = (auths: AccountAuthorization[], system: string): string => {
        let verifiedOn = "";
        auths?.map(auth => {
            if (auth.authorizationSystem === system) {
                verifiedOn = dayjs(auth.authorizationDate).isValid() ? new Date(Date.parse(auth.authorizationDate)).toLocaleString("en-US", DATE_TIME_FORMAT) : DISPLAY_PLACEHOLDER;
            }
        });
        return verifiedOn;
    };

    const mapSearchResults = (searchResponse: PatientAdminServiceIdentity[], inputSearchType: string): SearchResult[] => {
        const searchResults: SearchResult[] = [];
        searchResponse.map((res) => {
            const system = res.system ?? res.consumerIdentity.externalIdentifiers?.filter(f => f.idType == "mychartid")?.[0]?.system;
            const mychartUserName = res.mychartUsername ?? res.consumerIdentity.externalIdentifiers?.filter(f => f.idType == "mychartid")?.[0]?.idValue;
            const mfaEnabledDate = res.mfaConfig?.mfaEnabledDate && dayjs(res.mfaConfig?.mfaEnabledDate).isValid() ? new Date(res.mfaConfig?.mfaEnabledDate).toLocaleString("en-US", DATE_TIME_FORMAT) : DISPLAY_PLACEHOLDER
            const searchResult: SearchResult = {
                healthSystemId: res.consumerIdentity.healthSystemId,
                ehrDemographics: res.consumerIdentity.ehrDemographics,
                inputSearchType: inputSearchType,
                azureId: res.azureId,
                system: system,
                mrn: res.mrn,
                epi: res.epi,
                userLastSignedInDate: res.userLastSignedInDate && res.userLastSignedInDate != "N/A" ? new Date(Date.parse(res.userLastSignedInDate)).toLocaleString("en-US", DATE_TIME_FORMAT) : DISPLAY_PLACEHOLDER,
                mychartUserName: mychartUserName,
                userProvidenceAccountUsername: res.userProvidenceAccountUsername,
                isEmailVerified: res.isEmailVerified,
                requireCreateAccount: res.requireCreateAccount,
                requireMigrateAccount: res.requireMigrateAccount,
                accountAuthorizations: !res.consumerIdentity.accountAuthorizations ? [] : res.consumerIdentity.accountAuthorizations,
                isAuthorized: res.consumerIdentity.isAuthorized,
                externalIdentifiers: res.consumerIdentity.externalIdentifiers,
                accountCreationDate: res.userAccountCreationDate && dayjs(res.userAccountCreationDate).isValid() ? new Date(Date.parse(res.userAccountCreationDate)).toLocaleString("en-US", DATE_TIME_FORMAT) : DISPLAY_PLACEHOLDER,
                daysSinceAccCreation: res.userAccountCreationDate && dayjs(res.userAccountCreationDate).isValid() ? getDaysAgo(res.userAccountCreationDate).toString() + " days ago" : DISPLAY_PLACEHOLDER,
                daysSinceLogin: res.userLastSignedInDate && dayjs(res.userLastSignedInDate).isValid() ? getDaysAgo(res.userLastSignedInDate).toString() + " days ago" : DISPLAY_PLACEHOLDER,
                authorizationDate: getAuthorizationDate(res.consumerIdentity.accountAuthorizations, system),
                daysSinceAuth: getDaysAgo(getAuthorizationDate(res.consumerIdentity.accountAuthorizations, system)).toString() + " days ago",
                mfaVerificationMethod: res.mfaConfig?.preferredMfa ? res.mfaConfig?.preferredMfa : DISPLAY_PLACEHOLDER,
                mfaEnabledDate: mfaEnabledDate,
                daysSinceMFAEnabled: dayjs(mfaEnabledDate).isValid() ? getDaysAgo(mfaEnabledDate).toString() + " days ago" : DISPLAY_PLACEHOLDER,
                mfaState: mfaEnabledDate != DISPLAY_PLACEHOLDER ? MfaState.Enabled :
                    res.mfaConfig?.isDelayed ? MfaState.Delayed :
                        res.mfaConfig?.isOptedOut ? MfaState.Disabled :
                            MfaState.NotConfigured

            }
            searchResults.push(searchResult);
        })
        return searchResults;
    }

    useEffect(() => {
        auditPageView(instance, accounts, clientConfiguration, sessionId)
    }, []);

    const showSearchData = (searchResult: SearchResult[]) => {
        setSearchDisplayData(searchResult);
        setSearchDataDisplay(true);
    }

    const getDisplayScreen = () => {
        if (loading) {
            return (
                <>
                    <Grid container item xs alignItems='center' className='searchOutputBodyPlaceholder'>
                        <Grid item xs>
                            <Typography variant="body1">
                                Loading...
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            );
        }
        else {
            return (
                <>
                    {
                        !searchDataDisplay ?
                            <MultipleAccountsDisplayComponent searchTerm={searchTerm} multipleSearchResults={multipleData} showSearchData={showSearchData} /> :
                            <SearchResultDataComponent searchDisplayData={searchDisplayData} searchTerm={searchTerm} instance={instance} accounts={accounts} searchAccounts={searchMultiAccounts} />
                    }
                </>
            );
        }

    }

    return (
        <>
            <Grid container className="searchBoundingBox">
                <Grid container item md={12} sm={12} xs={12} className="searchTitle">
                    <Grid container item alignItems='center' direction='row' md={5} sm={6} xs={8}>
                        <img src={clientConfiguration.brandConfig?.logoUri} alt={clientConfiguration.brandConfig?.logoAltText} className='logo'/>
                        <Typography variant="h5" className="searchPageLabel">
                            {clientConfiguration.title}
                        </Typography>
                    </Grid>
                    <Grid container alignItems="center" item md={7} sm={6} xs={4} className="searchPageLogoutSection">
                        <Hidden smDown>
                            <Typography variant="h6" className="searchPageUsername">
                                {getDisplayUserName(accounts[0], true)}
                            </Typography>
                        </Hidden>
                        <Hidden mdUp>
                            <Typography variant="h6" className="searchPageUsername">
                                {getDisplayUserName(accounts[0], false)}
                            </Typography>
                        </Hidden>
                        <Button type="button" variant="outlined" color="secondary" fontSize="14px" onClick={logout} >SIGN-OUT</Button>
                    </Grid>
                </Grid>
                <form onSubmit={handleSubmit(({ accountId }) => onSearch(accountId))} className="searchForm">
                    <Grid container item md={12} sm={12} xs={12} direction='row' spacing={2} alignItems='center' >
                        <Grid item className='searchBoxLabel'>
                            <Typography variant="h4">
                                Account Search:
                            </Typography>
                        </Grid>
                        <Grid item md={8} sm={8} xs={8}>
                            <Controller
                                className='searchBoxInput'
                                as={TextField}
                                control={control}
                                variant="outlined"
                                defaultValue=""
                                name="accountId"
                                rules={{
                                    required: true
                                }}
                                id="accountId"
                                autoComplete="off"
                                fullWidth
                                error={errors.account?.type === "required"}
                                helperText={errors.account?.type === "required" && "Email, username, MRN, or EPI is required"}
                                placeholder="Providence account email, MRN, or EPI"
                                InputLabelProps={{
                                    shrink: true,
                                    error: false,
                                }}
                            />
                        </Grid>
                        <Grid container alignItems="center" item  md={2} sm={2} xs={2}>
                            <Button disabled={!isDirty || !isValid} type="submit" variant="contained" color="primary" fontSize="16px">Search</Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Grid container alignItems='flex-start' direction='column' className='ml20'>
                {getDisplayScreen()}
            </Grid>
        </>
    )
}

export default withLogger(SearchPage);
