import { createContext } from "react";
import axios from "axios";
import {BrandConfig, ClientConfig, DropDownItem} from "../types/serverConfig";
import {MyChartDomain} from "@Praia-Health/spi-common-utils/dist/mychart/types/mychartConfigTypes";

export const ClientConfigContext = createContext<ClientConfig>({
    epic: {
        ehrRegions: [] as string[]
    },
    mychart: {
        mychartPrefix: "",
        mychartDomains: [] as MyChartDomain[]
    },
    pasApiUrl: "",
    pimsApiUrl: "",
    tier: "",
    b2cTenantConfig: {
        scopes: [] as string[],
        tenantName: "",
        signInPolicy: "",
        clientId: "",
        redirectUri: "",
        postLogoutRedirectUri: ""
    },
    ui: {
        regionDropDownItems: [] as Array<DropDownItem>
    },
    brandConfig: {} as BrandConfig,
    title: "",
    userActivityLimitSeconds: 3600
});

export function createConfiguredApp(callback: any, loginTenant?: string) {
    axios.get(
        `/api/config?loginTenant=${loginTenant}`)
        .then(callback)
        .catch((err: any) => console.log(err));
}
