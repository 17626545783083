import {ClientLogger} from "@Praia-Health/spi-common-utils/dist/logging/clientLogger";
import {LoggerContext} from "@Praia-Health/spi-common-utils/dist/types/clientLoggerTypes";
import {v4 as uuidv4} from 'uuid';

export class ApiRequestLogger extends ClientLogger {

    constructor(context?: LoggerContext) {
        super({
            context: context || {}
        });
        this.addContext({
            '@app_name': 'SPI.PECAdminPortal',
            '@type': 'log',
            correlationId: uuidv4()
        })
    }
}