import { MrnType, SearchResult } from "../types/api";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import {ClientConfig, DropDownItem} from "../types/serverConfig";
import { AccountInfo } from "@azure/msal-browser";
dayjs.extend(utc);

export const DATE_TIME_FORMAT = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
} as Intl.DateTimeFormatOptions;

export const DISPLAY_PLACEHOLDER = "--";

/**
 * Returns true when the input is undefined or whitespace.
 * @param input
 */
export const isNullOrWhitespace = (input: string | undefined): boolean => {
    if (input) {
        return input.trim().length < 1;
    }
    return true;
};

// Shows the DEFAULT_PLACEHOLDER when the input is emptyOrWhitespace
export const sanitizeInput = (input: string | undefined): string => {
    if (isNullOrWhitespace(input)) {
        return DISPLAY_PLACEHOLDER;
    }
    return input as string;
};

export const getFullName = (firstName: string | undefined, lastName: string | undefined): string => {
    const fullName = isNullOrWhitespace(firstName) ? DISPLAY_PLACEHOLDER : `${firstName} ${lastName}`;
    return fullName;
};

export const getLastSSN = (inputSSN: string | undefined): string | undefined => {
    const last4SSN = isNullOrWhitespace(inputSSN) ? DISPLAY_PLACEHOLDER : inputSSN?.split("-").pop();
    return last4SSN;
};

/**
 * Joins two inputs with a pipe '|' unless the inputs are the same. When they are the same,
 * the input is return as itself
 * @param input1
 * @param input2
 */
export const joinValues = (input1: string, input2: string): string => {
    const returnValue =
        input1?.toLowerCase() === input2?.toLowerCase()
            ? input1
            : input1 + "|" + input2;
    return returnValue;
};

/**
 * Maps the MRN and Systems for a search results collection. Null values are ignored.
 * @param values
 */
export const filterMrns = (values: SearchResult[]): Array<MrnType> => {
    const mrns: MrnType[] = new Array<MrnType>();
    values.map((value) => {
        if (value?.mrn && value?.system) {
            mrns.push({ id: value.mrn, system: value.system });
        }
    });
    return mrns;
};

export const getMDYFormat = (date: string | undefined, isUTC = true): string => {
    if (date && !isNullOrWhitespace(date)) {
        if (isUTC) {
            const formattedDate = dayjs.utc(date).format('MM/DD/YYYY');
            return formattedDate;
        } else {
           return dayjs(Date.parse(date)).format("MM/DD/YYYY").toString();
        }
    }
    return sanitizeInput(date);
};

export const getAuthorizationMethod = (result: SearchResult): string => {
    let verifiedWith = "";
    if (result.requireMigrateAccount) {
        verifiedWith = "No SSO account";
    } else {
        result.accountAuthorizations?.map((auth) => {
            if (auth.authorizationSystem === result.system) {
                verifiedWith = auth.authorizationMethod;
            }
        });
    }
    return verifiedWith;
};

export const getRegion = (fullEhrSystemName: string, clientConfiguration: ClientConfig, toUpperCase = true): string | undefined => {
    const regionLabels: Array<DropDownItem> = clientConfiguration.ui.regionDropDownItems;
    const regionLabel = regionLabels.find((regionLabel) => {
        return regionLabel.value === fullEhrSystemName;
    });
    return (toUpperCase) ? regionLabel?.label?.toUpperCase() : regionLabel?.label;
};

// TODO: Search for " days ago" and use the getFormattedDateInDaysAgo
export const getFormattedDateInDaysAgo = (inputDate: string) : string => {
    if (dayjs(inputDate).isValid()) {
        const day = new Date(Date.parse(inputDate)).toLocaleString("en-US", DATE_TIME_FORMAT);
        return getDaysAgo(day).toString() + " days ago";
    } else {
        return DISPLAY_PLACEHOLDER;
    }
};

export const getFormattedDate = (inputDate: string) : string =>{
    const result  = dayjs(inputDate).isValid() ? new Date(Date.parse(inputDate)).toLocaleString("en-US", DATE_TIME_FORMAT) : DISPLAY_PLACEHOLDER;
    return result;
};

// TODO: why mix dayjs and native Date? dayjs for validation and native Date for content.
export const getDaysAgo = function (inputDate: string): number{
    return dayjs(inputDate).isValid() ? Math.round((new Date().getTime() - new Date(Date.parse(inputDate)).getTime()) / (1000 * 3600 * 24)) : 0;
};

export const getDisplayUserName = (account: AccountInfo, showEmail: boolean): string => {
    if(account) {
        return showEmail ? account.username : account.username.includes("@") ? account.username.split('@')[0] : account.username;
    }
    return '';
}


