import React, { useContext, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Grid, Typography, Box } from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import { DISPLAY_PLACEHOLDER } from '../../../utils/AccountDetailsUtil';
import Button from '../../../elements/Button';
import '../../../style.css';
import { ClientConfigContext } from "../../../config/clientConfig";
import { ApiStatus, resetMFAInfo } from "../api";
import { SessionContext } from '../../../session/sessionContext';
import { SearchResult } from '../../../types/api';
import { GridError } from './grid';
import { ModalLine } from './modal';
import {MfaState} from "../../../types/mfaState";
import withLogger from './HOC/WithLogger';

export type MFAResetResult = Pick<SearchResult, "mfaVerificationMethod" | "daysSinceMFAEnabled" | "mfaEnabledDate" | "mfaState">;

export type ResetMFAModalProps = {
    open: boolean,
    onClose: () => void,
    onReset: (resetResult: MFAResetResult) => void,
    userIdValue: string
}

const MFA_RESET_SEARCH_RESULT: MFAResetResult = {
    mfaVerificationMethod: DISPLAY_PLACEHOLDER,
    daysSinceMFAEnabled: "",
    mfaEnabledDate: DISPLAY_PLACEHOLDER,
    mfaState: MfaState.NotConfigured
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            minHeight: 224,
            paddingBottom: 28,
            paddingLeft: 28,
            paddingRight: 28,
            paddingTop: 15,
        },
        bodyBox: {
            flexGrow: 1,
        },
        bodyText: {
            color: '#212121',
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: 'normal',
            lineHeight: '21.6px',
        },
        cancelButton: {
            '& .MuiButtonBase-root': { // hack to override global !important button styles
                borderWidth: 1,
                height: 30,
                margin: '0px !important',
                maxWidth: '100% !important',
                minWidth: '0px !important',
                width: '134px !important'
            }
        },
        header: {
            textAlign: 'center'
        },
        headerText: {
            fontSize: 24,
            lineHeight: '16px',
            paddingLeft: 55,
            paddingRight: 48,
            paddingTop: 18,
            paddingBottom: 18,
        },
        modal: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: 20,
            boxShadow: theme.shadows[5],
            left: '50%',
            minHeight: 278,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 352,
        },
        resetButton: {
            textAlign: 'right',
            '& .MuiButtonBase-root': { // hack to override global !important button styles
                borderWidth: 1,
                height: 30,
                margin: '0px !important',
                maxWidth: '100% !important',
                minWidth: '0px !important',
                width: '134px !important'
            }
        },
    }),
);

/**
 * Modal for resetting a user's MFA
 * TODO: Refactor into a reusable modal with a header and actions/buttons
 */
function ResetMFAModal({ open, onClose, onReset, userIdValue }: ResetMFAModalProps) {
    const { instance, accounts } = useMsal();
    const clientConfiguration = useContext(ClientConfigContext);
    const sessionId = useContext(SessionContext);
    const classes = useStyles();
    const [hasError, setError] = useState(false);

    const handleClose = () => {
        setError(false);
        onClose();
    }

    const resetMFA = async () => {
        const status  = await resetMFAInfo(userIdValue, instance, accounts, clientConfiguration, sessionId);

        if (status === ApiStatus.Success) {
            setError(false);
            onReset(MFA_RESET_SEARCH_RESULT);
        } else {
            setError(true);
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box className={classes.modal}>
                <Box className={classes.header}>
                    <Typography variant="h3" className={classes.headerText}>
                        Two-Step Verification
                    </Typography>
                </Box>
                <ModalLine />
                <Grid container direction='column' item xs={12} className={classes.body}>
                    <Grid item xs={12} className={classes.bodyBox}>
                        <Typography className={classes.bodyText} style={{fontWeight: 600}}>
                            Are you sure you would like to RESET the two-step verification for this patient?
                        </Typography>
                        <br/>
                        <Typography className={classes.bodyText} >
                            Upon reset, this patient will be prompted to set up their two-step verification on the next sign in.
                        </Typography>
                    </Grid>
                    {hasError && <GridError>Reset operation failed, please try again later.</GridError>}
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6} className={classes.cancelButton}>
                                <Button type="button" variant="outlined" color="secondary" onClick={handleClose} fontSize="14px">
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={6} className={classes.resetButton}>
                                <Button type="button" variant="contained" color="secondary" onClick={resetMFA} fontSize="14px">
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

        </Modal>
    );
}

export default withLogger(ResetMFAModal);
