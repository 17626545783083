import React, {Fragment, useContext, useEffect} from 'react';
import {Grid, Radio, Typography } from '@material-ui/core';
import Button from '../../../elements/Button';
import Paper from '../../../elements/Paper';
import { EhrDemographics, SearchResult } from '../../../types/api';
import {
    joinValues,
    getFullName,
    getLastSSN,
    filterMrns,
    getAuthorizationMethod,
    sanitizeInput,
    getRegion
} from '../../../utils/AccountDetailsUtil';
import {CardType} from "../../../types/cardTypes";
import _ from "lodash";
import {ClientConfigContext} from "../../../config/clientConfig";
import withLogger from './HOC/WithLogger';

type MultipleAccountDisplayComponentProps = {
    searchTerm: string;
    multipleSearchResults: SearchResult[] | undefined;
    showSearchData: any;
}

const healthSystemIdProp = 'healthSystemId';

function MultipleAccountsDisplayComponent({ searchTerm, multipleSearchResults, showSearchData }: MultipleAccountDisplayComponentProps) {

    const clientConfiguration = useContext(ClientConfigContext);
    const [selectedAccount, setSelectedAccount] = React.useState<string>("");
    const [groupedSearchResults, setTransformedSearchResults] = React.useState<{ [key: string]: SearchResult[] }>();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAccount(event.target.value);
    };

    const selectAccount = () => {
        const selectedAccounts: SearchResult[] = [];
        if(groupedSearchResults && Object.keys(groupedSearchResults).length > 0){
            Object.keys(groupedSearchResults).length == 1 ? selectedAccounts.push(...Object.values(groupedSearchResults)[0]) :
                Object.entries(groupedSearchResults)
                .map((item) => { if (item[0] === selectedAccount) { selectedAccounts.push(...item[1]) } });
        }
        if (selectedAccounts && selectedAccounts.length > 0) {
            showSearchData(selectedAccounts);
        }
    }

    useEffect(() => {
        (async () => {
            await groupByHealthSystemId();
        })();
    }, [])

    //Group the search results to identify linked records
    const groupByHealthSystemId = async () => {
        multipleSearchResults?.map(searchResult => {
            if(!searchResult.healthSystemId){
                searchResult.healthSystemId = (Math.random() + 1).toString(36).substring(7);
            }
        });
        setTransformedSearchResults(_.groupBy(multipleSearchResults, healthSystemIdProp))
    }

    const renderSearchMetadata = () => {
        const inputSearchType = multipleSearchResults?.[0]?.inputSearchType ?? ""
        //Findout the actual number items after grouping the result. For linked records, healthsystemId will be same.
        //Hence, after grouping the recorods with same healthsystemId will be grouped usder one item in groupedSearchResults
        const searchResultLength = groupedSearchResults ? Object.keys(groupedSearchResults)?.length : 0;
        if  (searchResultLength >= 1) {
            return (
                <Grid item>
                    <Typography variant="h3" className="searchMetadata">
                            We found <span>{searchResultLength > 1 ? "more than one " : "one "} </span>
                            account for {inputSearchType.toLowerCase()==="username"?
                            inputSearchType: inputSearchType.toUpperCase()} -
                        <span className='searchMessage'>{searchTerm}</span>.
                    </Typography>
                    <Typography variant="h4" className="searchMetadataCTA">
                        Verify and select account
                    </Typography>
                </Grid>
            );
        }
        else if (searchResultLength == 0 && searchTerm === "") {
            return (
                <>
                    <Grid item>
                        <Typography variant="h3" className="searchMetadata">
                            We recommend you search by patient MRN number.
                        </Typography>
                    </Grid>
                </>
            )
        } else {
            return (
                <>
                    <Grid container direction='column' alignItems='center' className='searchOutputBodyPlaceholder'>
                        <Grid item >
                            <Typography variant="body1">
                                No Results for "{searchTerm}"
                            </Typography>
                            <Typography variant="body1">
                                Search by
                            </Typography>
                            <Typography variant="body2">
                                Providence account email, MRN, or EPI
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }

    const getSelectionItem = (healthSystemId: string) => {
        if (groupedSearchResults && Object.keys(groupedSearchResults).length > 1)
            return (
                <>
                    <Grid item xs={1}>
                        <Radio
                            checked={selectedAccount === healthSystemId}
                            onChange={handleChange}
                            value={healthSystemId}
                            data-dd-action-name="MRN card selection"
                        />
                    </Grid>
                </>
            )
    }

    const getButtonItem = () => {
        const buttonText = groupedSearchResults && Object.keys(groupedSearchResults).length > 1 ? "SELECT ACCOUNT" : "NEXT";
        if (multipleSearchResults && multipleSearchResults.length > 0) {
            return (
                <Grid item className ={buttonText=="SELECT ACCOUNT" ? 'mt20 ml40' : 'mt20'}>
                    <Button type="button" variant="contained" color="secondary" fontSize="12px" onClick={selectAccount}>
                        {buttonText}
                    </Button>
                </Grid>
            );
        }
    }

    const getMergedSearchResult = (values: SearchResult[]) => {
        if(values.length > 1){
            const mergedSearchResult = {...values[0]};
            const firstResult = values[0].ehrDemographics;
            const secondResult = values[1].ehrDemographics;
            const mergedDemographics: EhrDemographics = {
                email: sanitizeInput(firstResult?.email),
                dateOfBirth: sanitizeInput(firstResult?.dateOfBirth),
                ssn: sanitizeInput(getLastSSN(firstResult?.ssn)),
                idType: joinValues(sanitizeInput(firstResult?.idType), sanitizeInput(secondResult?.idType)),
                idValue: joinValues(sanitizeInput(firstResult?.idValue), sanitizeInput(secondResult?.idValue)),
                firstName: getFullName(firstResult?.firstName, firstResult?.lastName),
                lastName: ""
            }
            mergedSearchResult.ehrDemographics = mergedDemographics;

            const myChartNames = values.map(val => {
                return `${sanitizeInput(val.mychartUserName).toLowerCase()} (${getRegion(val.system, clientConfiguration)})`;
            });
            mergedSearchResult.mychartUserName = myChartNames.join('|');
            return mergedSearchResult;
        }else{
            return values[0];
        }
    }


    const getAccountInfoCards = () => {
        if (groupedSearchResults) {
            // TODO: Passing undefined into onUnlink is going to break the Paper component
            return Object.values(groupedSearchResults).map((values) => {
                    const mergedSearchResult = getMergedSearchResult(values)
                    return (
                        <Fragment key={mergedSearchResult.healthSystemId}>
                            <Grid container direction='row' style={{ maxWidth: "500px", minWidth: "500px", marginBottom:"20px", marginRight:"20px"}}>
                                {getSelectionItem(mergedSearchResult.healthSystemId)}
                                <Grid item xs>
                                    <Paper variant='outlined' cardType={CardType.NormalCard} onUnlink={undefined}
                                        inputData={mergedSearchResult} verifiedWith={getAuthorizationMethod(mergedSearchResult)}
                                        mrns={filterMrns(values)} />
                                </Grid>
                            </Grid>
                        </Fragment>
                    )

            })

        }
    }

    return (
        <>
            {renderSearchMetadata()}
            <Grid container direction='row'>
                {getAccountInfoCards()}
            </Grid>
            {getButtonItem()}
        </>

    )
}

export default withLogger(MultipleAccountsDisplayComponent);
