import React from 'react';
import {TextField, InputAdornment, Grid} from '@material-ui/core';
import withLogger from './HOC/WithLogger';
type EmailUpdateComponentProps = {
    show: boolean,
    setShow: (arg:boolean)=>void
    message: string,
    successful: boolean,
    newEmailSame: boolean,
}

function EmailUpdateComponent({ show, setShow, message, successful, newEmailSame }: EmailUpdateComponentProps) {
    
    if (show && successful) {
        if (newEmailSame) {
            return (
                <>
                    <Grid container item xs={12} className="temporaryPassword" id="pico">
                        <TextField id="outlined-read-only-input"
                            variant="outlined"
                            value={"Email not updated: The email entered is the current email."}
                            fullWidth={true}
                            className="temporaryPasswordTextField"
                            InputProps={{
                                readOnly: true, 
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img src={'/BlueSubtract.svg'} alt="Unchecked"
                                            onClick={()=>{
                                                setShow(false);
                                            }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </>
            );
        } else {
            return (
                <>
                    <Grid container item xs={12} className="temporaryPassword" id="pico">
                        <TextField id="outlined-read-only-input"
                            variant="outlined"
                            value={"Email updated: " + message}
                            fullWidth={true}
                            className="temporaryPasswordTextField"
                            InputProps={{
                                readOnly: true, 
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img src={'/BlueSubtract.svg'} alt="Unchecked"
                                            onClick={()=>{
                                                setShow(false);
                                            }}
                                        />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </>
            );
        }
    } else if (show && !successful) {
        return (
            <>
                <Grid container item xs={12} className="temporaryPassword" id="pico">
                    <TextField id="outlined-read-only-input"
                        variant="outlined"
                        value={"Email not updated: " + message}
                        fullWidth={true}
                        className="temporaryPasswordTextField"
                        InputProps={{
                            readOnly: true, 
                            endAdornment: (
                                <InputAdornment position="end">
                                    <img src={'/BlueSubtract.svg'} alt="Unchecked"
                                        onClick={()=>{
                                            setShow(false);
                                        }}
                                    />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
            </>
        );
    } else {
        return (
            <Grid item xs={12} className="searchOutputSpacer">
                &nbsp;
            </Grid>
        );
    }
}

export default withLogger(EmailUpdateComponent);