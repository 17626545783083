import { ClientConfig } from "../types/serverConfig";

export function removeMigratedAccountPrefix(username: string, clientConfiguration: ClientConfig): string {
  if (!username) return username;
 
  const mychartPrefix = clientConfiguration.mychart.mychartPrefix;
  const epicEhrRegions: Array<string> = clientConfiguration.epic.ehrRegions;
  const prefixRegex = new RegExp(`${mychartPrefix}(${epicEhrRegions.join("|")})+\\.`);

  username = username.replace(prefixRegex, '');

  return username
}