import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
    root: {
        background: '#CCCCCC',
        height: 1,
        maxHeight: 1,
        minHeight: 1
    },
}));

/**
 * A horiziontal line to dive a grid
 */
function ModalLine() {
    const classes = useStyles();
    return <Box className={classes.root} role="separator" />;
}

export default ModalLine;
