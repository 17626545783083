import React, { ReactNode } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';

export type GridErrorProps = {
    children: ReactNode;
}

/**
 * An error message for use inside a grid
 */
function GridError({children}: GridErrorProps) {
    const theme = useTheme();
    return (
        <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ color: theme.palette.error.main }}>
                {children}
            </Typography>
        </Grid>
    );
}

export default GridError;
