import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";
import { ClientConfigContext, createConfiguredApp } from "./config/clientConfig";
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { ClientConfig } from './types/serverConfig';
import { getTheme } from './theme';
import {ClientLogger} from '@Praia-Health/spi-common-utils/dist/logging/clientLogger'
import { ApiRequestLogger } from './features/search/ApiRequestLogger';

const LOGIN_TENANT_PARAM = "loginTenant";

function renderAppWithConfiguration(response: any) {

    const configData = response.data;
    const clientConfig: ClientConfig = configData;
    const theme = createMuiTheme(getTheme(clientConfig.brandConfig.theme));
    const logger: ClientLogger = new ApiRequestLogger();
    logger.debug('rendering application with configuration');
    // If no tenant is specified, we are authenticating with AAD
    const config: Configuration = {
        auth: {
            authority: clientConfig.b2cTenantConfig.signInPolicy,
            clientId: clientConfig.b2cTenantConfig.clientId,
            redirectUri: clientConfig.b2cTenantConfig.redirectUri
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
        }
    };
    

    //const theme: Theme = createMuiTheme(theme);

    const pca = new PublicClientApplication(config);
    ReactDOM.render(
        <React.StrictMode>
            <ClientConfigContext.Provider value={clientConfig}>
                <Router>
                    <ThemeProvider theme={theme}>
                        <Suspense fallback="loading">
                            <MsalProvider instance={pca}>
                                <App />
                            </MsalProvider>
                        </Suspense>
                    </ThemeProvider>
                </Router>
            </ClientConfigContext.Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
const searchParams = new URLSearchParams(document.location.search);
const loginTenant = searchParams.get("login");
if(loginTenant != null) {
    window.sessionStorage.setItem(LOGIN_TENANT_PARAM, loginTenant)
}
createConfiguredApp(renderAppWithConfiguration, window.sessionStorage.getItem(LOGIN_TENANT_PARAM) || undefined);
