export default (theme) => {
    return({
      root: {
        whiteSpace: 'nowrap',
        borderRadius: 100,
        height: 36,
        width: 40,
        [theme.breakpoints.up('sm')]: {
            minWidth: 100
        },
        [theme.breakpoints.up('md')]: {
            minWidth: 150
        },
        padding: "0 3em",
      },
      label: {
        textTransform: 'uppercase',
        fontWeight: 500,
        lineHeight: '19.2px',
        fontFamily: 'Barlow',
      },
      outlined: {
        border: '2px solid ' + theme.palette.primary.border,
        '&:hover': {
          border: '2px solid ' + theme.palette.primary.border,
        },
        [theme.breakpoints.down('xs')]: {
            width: 10
        },
      },
      contained: {
        backgroundColor: theme.palette.primary.main,
        whiteSpace: 'nowrap',
        height: 36,
        width: 60,
        [theme.breakpoints.up('sm')]: {
            minWidth: 110
        },
        [theme.breakpoints.up('md')]: {
            minWidth: 160
        },
        padding: "0 3em",
        boxShadow: theme.shadows[0],
        '&:hover': {
          boxShadow: theme.shadows[0],
          backgroundColor: theme.palette.primary.main,
        },
        '&:active': {
          boxShadow: theme.shadows[0],
          backgroundColor: theme.palette.primary.main,
        },
        '&:disabled': {
          color: '#999999',
          backgroundColor: '#FFFFFF',
          border: '1px solid #999999',
        }
      },
      containedSecondary: {
        backgroundColor: theme.palette.secondary.main,
        height: 32,
        width: 144,
        whiteSpace: 'nowrap',
        boxShadow: theme.shadows[0],
        marginRight: "20px !important",
        '&:hover': {
          boxShadow: theme.shadows[0],
          backgroundColor: theme.palette.secondary.main,
        },
        '&:active': {
          boxShadow: theme.shadows[0],
          backgroundColor: theme.palette.secondary.main,
        },
        '&:disabled': {
          color: '#999999',
          backgroundColor: '#FFFFFF',
          border: '1px solid #999999',
        }
      },
      outlinedSecondary: {
        backgroundColor: '#FFFFFF',
        whiteSpace: 'nowrap',
        height: 32,
        maxWidth:100,
        padding: "0 3em",
        boxShadow: theme.shadows[0],
        '&:hover': {
          boxShadow: theme.shadows[0],
          backgroundColor: '#FFFFFF',
        },
        '&:active': {
          boxShadow: theme.shadows[0],
          backgroundColor: '#FFFFFF',
        },
        '&:disabled': {
          color: '#999999',
          backgroundColor: '#FFFFFF',
          border: '1px solid #999999',
        }
      },
      sizeLarge: {
        whiteSpace: 'nowrap',
        height: 36,
        minWidth: 160,
        padding: "0 3em",
      },
    });
  }
