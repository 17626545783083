import {IPublicClientApplication, AccountInfo} from "@azure/msal-browser";

export default async function getAccessToken(instance: IPublicClientApplication, accounts: AccountInfo[], scopes: string[]) {
    return await instance.acquireTokenSilent({
        account: accounts[0],
        scopes,
    }).then((response: { accessToken: any; }) => {
        if (response.accessToken) {
            return response.accessToken;
        }
        return null;
    });
}
