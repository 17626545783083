import React, { useEffect} from 'react';

const withLogger = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const logComponentRenders = (props : any) => {
    const logToConsole =  (logMessage: string) => {
        console.log(logMessage);
    }
    useEffect(() => {
      // Log data on component mount
      logToConsole(`Component ${WrappedComponent.name} mounted.`);
      return () => {
        // Log data on component unmount
        logToConsole(`Component ${WrappedComponent.name} unmounted.`);
      };
    }, []);

    useEffect(() => {
      // Log data on component update
      logToConsole(`Component ${WrappedComponent.name} updated.`);
    });

    return <WrappedComponent {...props}/>;
  };

  return logComponentRenders;
};

export default withLogger;
