export default (theme) => {
    return({
        root:{
            border: "1px solid #979797",
            'border-radius': "14px",
        },
        paper: {
            padding: theme.spacing(1),
            margin: 'auto',
            maxWidth: '500px',
            maxHeight: '259px',
            minWidth: '500px',
        },
    })
}