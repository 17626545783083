import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Grid, Typography } from '@material-ui/core';
import Button from '../../../elements/Button';
import '../../../style.css';
import { useInterval } from '../../../hooks/useInterval';

export type SessionTimeoutModalProps = {
    open: boolean,
    onClose: () => void,
    onSelection: (sessionContinue: boolean) => void
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 400,
            height: 250,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '24px',
            boxShadow: theme.shadows[5],
            paddingLeft: '10px',
            paddingRight: '10px'
        },
    }),
);

function SessionTimeoutModal({ open, onClose, onSelection }: SessionTimeoutModalProps) {
    const classes = useStyles();
    const modalTimeLimitInSeconds = 60; //In Seconds
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [modalTimerInSeconds, setSeconds] = useState(modalTimeLimitInSeconds);

    useInterval(() => {
        if (open) {
            if (modalTimerInSeconds <= 0) {
                onSelection(false);
            } else {
                setSeconds(modalTimerInSeconds - 1);
            }
        } else {
            setSeconds(modalTimeLimitInSeconds);
        }
    }, 1000);

    const closeModal = () => {
        onClose();
    }

    const body = (
        <Grid container item xs={12} alignItems='center' style={modalStyle} className={classes.paper}>
            <Grid container item xs={12} alignItems='center'>
                <Typography variant="h3">
                    You are about to logout
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ height: "0px", border: "1px solid #D2D2D2" }} />
            <Grid container alignItems="center" item xs={12}>
                <Typography variant="subtitle2">
                    You're being timed out due to inactivity. Please choose to stay signed in or to logout. Otherwise, you will be logged out automatically in 1 minute.
                </Typography>
            </Grid>
            <Grid container alignItems='center' direction='row' spacing={1}>
                <Grid item xs>
                    <Button type="button" id='logout' variant="outlined" color="secondary" fontSize="14px" onClick={() => { closeModal(); onSelection(false) }} heightOverride="28px" widthOverride="96px">
                        Log out
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button type="button" id='continue' variant="contained" color="secondary" onClick={() => { closeModal(); onSelection(true) }} fontSize="14px" heightOverride="28px" widthOverride="108px">
                        continue({modalTimerInSeconds})
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Modal
            open={open}
            onClose={closeModal}
        >
            {body}
        </Modal>
    );
}

export default SessionTimeoutModal;
