import React, { useContext, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Grid, Typography, TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-common';
import Button from '../../../elements/Button';
import '../../../style.css';
import {ClientConfigContext} from "../../../config/clientConfig";
import { ApiStatus,  getMrnData, linkRecord } from '../api';
import { MrnData } from '../../../types/api';
import { SessionContext } from '../../../session/sessionContext';
import withLogger from './HOC/WithLogger';

export type AddLinkedRecordModalProps = {
    open: boolean,
    handleClose: () => void,
    healthSystemId: string,
    searchMethod: (searchTerm: string, region?: string, healthSystemId?: string) => Promise<void>,
    searchTerm: string,
    region: string,
}

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 448,
            height: 344,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '24px',
            boxShadow: theme.shadows[5],
        },
    }),
);

function AddLinkedRecordModal({ open, handleClose, healthSystemId, searchMethod, searchTerm, region }: AddLinkedRecordModalProps) {
    const msalContext = useMsal();
    const { instance } = msalContext;
    const accounts = msalContext.accounts as AccountInfo[];
    const clientConfiguration = useContext(ClientConfigContext);
    const sessionId = useContext(SessionContext);
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [ modalStyle ] = useState(getModalStyle);
    const { handleSubmit, errors, control, formState } = useForm({ mode: 'onChange' });
    const { isDirty, isValid } = formState;
    const [mrnDetails, setMrnDetails] = useState<MrnData>();
    const [mrn, setMrn] = useState('');
    const [myChartLogin, setMyChartLogin] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [ehrSystem, setEhrSystem] = useState(region);
  
    const closeModal = () => {
        setErrorMessage("");
        setMrnDetails(undefined);
        setMrn('');
        setMyChartLogin('');
        handleClose();
    }

    const onSubmit = async (data: {mrn: string}) => {
        setMrn(data.mrn?.trim());
        const result = await getMrnData(data.mrn, instance, accounts, clientConfiguration, sessionId);
        if (result.status === ApiStatus.Success && result.data) {
            const mrnData = result.data;
            setErrorMessage(mrnData.ehrSystem === region?
                "There is already an MRN linked to this account from this region."
                : "");
            setMrnDetails(mrnData);
            setMyChartLogin(mrnData.myChartLogin);
            setEhrSystem(mrnData.ehrSystem);
        } else {
            setErrorMessage("Something went wrong, try again later.");
        }
    }


    const createMyChartAccountIfMissing = async () : Promise<string | undefined> => {
         if(!myChartLogin && mrnDetails){
            setErrorMessage("MyChart account does not exist")
            return undefined;
            
        }else{
            return myChartLogin;
        }
    }


    const handleLinkRecord = async () => {
        const myChartUsername = await createMyChartAccountIfMissing();
        if (myChartUsername) {
            const { status } = await linkRecord(mrn, myChartUsername, healthSystemId, instance, accounts, clientConfiguration, sessionId);
            if (status === ApiStatus.Success) {
                setErrorMessage("")
                closeModal();
                searchMethod(searchTerm, ehrSystem, healthSystemId);
            } else {
               setErrorMessage("Something went wrong, try again later.")
            }
        }
    }

    const renderSearchResults = () => {
        if (mrnDetails) {
            return (
                <Grid container item xs={12} className="mrnSearchResults">
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            {mrnDetails.name}
                        </Typography>
                    </Grid>
                    <Grid container item xs={4}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                MRN
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {mrn}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                MyChart Username
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {mrnDetails.myChartLogin}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )
        } else {
            return <Grid item xs={12} className="mrnSearchResults"></Grid>;
        }
    }

    const renderErrorMessage = () => {

            return (
               <Grid item xs={12}  style={{marginRight:20}}>
                    <Typography variant="subtitle1" color='error' align='right' >
                        {errorMessage || <>&nbsp;</>}
                    </Typography>
                </Grid>
            )

    }

    const renderLinkRecordButton = () => {
        if (mrnDetails && !errorMessage) {
            return (
                <>
                    <Grid item md={2} xs={1} />
                    <Grid item md={3} xs={4}>
                        <Button disabled={!isDirty || !isValid} type="button" variant="contained" color="secondary" onClick={handleLinkRecord} fontSize="14px" heightOverride="28px" widthOverride="200px">
                            Link Record
                        </Button>
                    </Grid>
                </>
            );
        } else {
            return <></>;
        }
    }

    const body = (
        <form onSubmit={handleSubmit(onSubmit)} style={modalStyle} className={classes.paper}>
            <Grid container item xs={12} data-testid="searchGrid">
                <Grid item xs={12} style={{height: "44px", marginLeft: "20px", marginTop: "10px"}}>
                    <Typography variant="h3">
                        Search for account
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{height: "0px", border: "1px solid #D2D2D2"}} />
                <Grid container alignItems="center" item xs={8} style={{height: "62px", marginLeft: "20px"}}>
                    <Typography variant="subtitle2">
                        This will grant access to the patient’s records to this SSO account.
                    </Typography>   
                </Grid>
                <Grid container item xs={12} direction='row' spacing={2} alignItems='center'>
                    <Grid item md={6} sm={7} xs={7}>
                        <Controller
                            className='mrnSearchControls'
                            as={TextField}
                            control={control}
                            variant="outlined"
                            defaultValue=""
                            name="mrn"
                            rules={{
                                required: true
                            }}
                            id="mrn"
                            autoComplete="off"
                            fullWidth
                            error={errors.mrn?.type === "required"}
                            helperText={errors.mrn?.type === "required" && "MRN required"}
                            placeholder="Search MRN"
                            InputLabelProps={{
                                shrink: true,
                                error: false,
                            }}
                        />
                    </Grid>
                    <Grid container alignItems="center" item md={2} sm={2} xs={2} className='mrnSearchControls'>
                        <Button disabled={!isDirty || !isValid} type="submit" variant="contained" color="primary" fontSize="16px">Search</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}> 
                    <hr className="linkedRecordModalDividerLine" />
                </Grid>
                {renderSearchResults()}
                {renderErrorMessage()}
                <Grid item md={3} xs={4} style={{marginLeft: "20px", marginRight: "28px"}}>
                    <Button type="button" variant="outlined" color="secondary" fontSize="14px" onClick={closeModal} heightOverride="28px" widthOverride="96px">
                        Cancel
                    </Button>
                </Grid>
                {renderLinkRecordButton()}
            </Grid>
        </form>
    );

    return (
        <Modal
            open={open}
            onClose={closeModal}
        >
            {body}
        </Modal>
    );
}

export default withLogger(AddLinkedRecordModal);
