import React,{useContext} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { removeMigratedAccountPrefix } from '../../../utils/Prefix';
import {ClientConfigContext} from "../../../config/clientConfig";
import {DISPLAY_PLACEHOLDER} from '../../../utils/AccountDetailsUtil'
import withLogger from './HOC/WithLogger';

type EmailComponentProps = {
    isEmailVerified: boolean,
    isNewEmail: boolean,
    userProvidenceAccountUsername: string,
    userAccountCreationDate?: string,
    userLastSignedInDate?: string,
    daysSinceSignin?: string,
    daysSinceAccCreation?: string,
    mfaVerificationMethod?: string,
    daysSinceMFAEnabled?: string,
    mfaEnabledDate?: string,
    mfaState? : string
}

function EmailComponent({
    isEmailVerified, isNewEmail, userProvidenceAccountUsername, userAccountCreationDate, userLastSignedInDate,
    daysSinceSignin, daysSinceAccCreation, mfaVerificationMethod,
    daysSinceMFAEnabled,mfaEnabledDate, mfaState
}: EmailComponentProps) {
    const clientConfiguration = useContext(ClientConfigContext);
    
    const verified = (
        <Grid container direction='row' className='mt5'>
            <Grid item>
                <img src={'/GreenCheckmark.svg'} alt="Checked" />
            </Grid>
            <Grid item>
                <Typography variant='subtitle2' className='ml5'>
                    Verified
                </Typography>
            </Grid>
        </Grid>
    );

    const notVerified = (
        <Grid container direction='row' className='mt5'>
            <Grid item>
                <img src={'/BlueExclamationPoint.svg'} alt="Unchecked" />
            </Grid>
            <Grid item>
                <Typography variant="subtitle2" className='ml5'>
                    Not verified
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Grid container item direction="column" xs={3}>
                <Grid container spacing={1} direction='row'>
                    <Grid item>
                        <Typography variant="h5">
                            Sign-In Name
                        </Typography>
                    </Grid>
                    <Grid item>
                        {isEmailVerified || isNewEmail ? verified : notVerified}
                    </Grid>
                </Grid>
                <Grid item className='mlt20'>
                    <Typography variant="subtitle1" className="signInName">
                        {removeMigratedAccountPrefix(userProvidenceAccountUsername, clientConfiguration)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="column" className='ml20'>
                <Grid container direction='row'>
                    <Grid container item direction='column' xs={2} className='mw200'>
                        <Grid item>
                            <Typography variant="subtitle1" className="accountDetailsValue" >
                                SSO acct created
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle2' className="accountDetailsHeader" >
                                {userAccountCreationDate}
                            </Typography>
                        </Grid>
                        <Grid item>
                            { daysSinceAccCreation != DISPLAY_PLACEHOLDER && <Typography variant='subtitle2' className="accountDetailsHeader">
                                ({daysSinceAccCreation})
                            </Typography> }
                        </Grid>
                    </Grid>
                    <Grid container item direction='column' xs={2}>
                        <Grid item>
                            <Typography variant='subtitle1' className="accountDetailsValue">
                                 Last login
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle2' className="accountDetailsHeader">
                                {userLastSignedInDate}
                            </Typography>
                        </Grid>
                        <Grid item>
                           { daysSinceSignin != DISPLAY_PLACEHOLDER && <Typography variant='subtitle2' className="accountDetailsHeader">
                                ({daysSinceSignin})
                            </Typography> }
                        </Grid>
                    </Grid>
                    <Grid container item direction='column' xs={4}>
                        <Grid item>
                            <Typography variant='subtitle1' className="mfaStatusHeader">
                                Two-step verification {mfaState}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle2' className="mfaDetailsValue">
                                {mfaVerificationMethod == DISPLAY_PLACEHOLDER ? mfaVerificationMethod : ("Verification method: " +mfaVerificationMethod) }
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle2' className="mfaDetailsValue">
                                {mfaEnabledDate == DISPLAY_PLACEHOLDER ? mfaEnabledDate : (mfaEnabledDate + ' (' + daysSinceMFAEnabled + ')')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default withLogger(EmailComponent);
