import React, { useContext, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Grid, Typography} from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-common';

import Button from '../../../elements/Button';
import '../../../style.css';
import {ClientConfigContext} from "../../../config/clientConfig";
import {ApiStatus, deleteRecord} from "../api";
import { SessionContext } from '../../../session/sessionContext';
import withLogger from './HOC/WithLogger';

type LinkedRecordRemovalModalProps = {
    open: boolean,
    handleClose: () => void,
    healthSystemId: string,
    region: string,
    searchMethod: (searchTerm: string, region?: string) => Promise<void>,
    searchTerm: string,
    name: string,
    mrn: string,
    myChartUsername: string,
    system: string,
}

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: 448,
            height: 344,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '24px',
            boxShadow: theme.shadows[5],
        },
    }),
);

function LinkedRecordRemovalModal({ open, handleClose, healthSystemId, searchMethod, searchTerm, mrn, name, myChartUsername, system }: LinkedRecordRemovalModalProps) {
    const msalContext = useMsal();
    const { instance } = msalContext;
    const accounts = msalContext.accounts as AccountInfo[];
    const clientConfiguration = useContext(ClientConfigContext);
    const sessionId = useContext(SessionContext);
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [ modalStyle ] = useState(getModalStyle);
    const [errorState, setErrorState] = useState(false);

    const closeModal = () => {
        setErrorState(false);
        handleClose();
    }

    const submitDeleteRecord = async () => {
        const { status } = await deleteRecord(mrn, myChartUsername, healthSystemId, instance, accounts, clientConfiguration, sessionId);

        if (status === ApiStatus.Success) {
            setErrorState(false);
            closeModal();
            searchMethod(searchTerm, "");
        } else {
            setErrorState(true);
        }
    };

    const renderMrnInfo = () => {
        return (
            <Grid container item xs={12} className="mrnSearchResults">
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        {name}
                    </Typography>
                </Grid>
                <Grid container item xs={4}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            MRN
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {mrn}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={8}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            MyChart Username
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            {myChartUsername}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const renderErrorMessage = () => {
        if (errorState) {
            return (
                <>
                    <Grid item xs={5} />
                    <Grid item xs={7}>
                        <Typography variant="subtitle1" style={{color: "red"}}>
                            Error deleting record, try again later.
                        </Typography>
                    </Grid>
                </>
            )
        } else {
            return (
                <Grid item xs={12} style={{height: "24.5px"}}/>
            )
        }
    }

    const renderDeleteRecordButton = () => {
        return (
            <>
                <Grid item md={3} xs={2} />
                <Grid item md={3} xs={3}>
                    <Button type="button" variant="contained" color="secondary" onClick={submitDeleteRecord} fontSize="14px" heightOverride="28px" widthOverride="108px">
                        Confirm
                    </Button>
                </Grid>
            </>
        );
    }

    const body = (
        <Grid container item xs={12} style={modalStyle} className={classes.paper}>
            <Grid item xs={12} style={{height: "44px", marginLeft: "20px", marginTop: "10px"}}>
                <Typography variant="h3">
                    {system?.toUpperCase()} - Remove MRN: {mrn}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{height: "0px", border: "1px solid #D2D2D2"}} />
            <Grid container alignItems="center" item xs={8} style={{height: "62px", marginLeft: "20px"}}>
                <Typography variant="subtitle2">
                    This will remove access to the patient’s records via this SSO account. Are you sure you want to proceed?
                </Typography>   
            </Grid>
            <Grid item xs={12}>
                <hr className="linkedRecordModalDividerLine" />
            </Grid>
            {renderMrnInfo()}
            {renderErrorMessage()}
            <Grid item md={3} xs={5} style={{marginLeft: "20px", marginRight: "28px"}}>
                <Button type="button" variant="outlined" color="secondary" fontSize="14px" onClick={closeModal} heightOverride="28px" widthOverride="96px">
                    Cancel
                </Button>
            </Grid>
            {renderDeleteRecordButton()}
        </Grid>
    );

    return (
        <Modal
            open={open}
            onClose={closeModal}
        >
            {body}
        </Modal>
    );
}

export default withLogger(LinkedRecordRemovalModal);
